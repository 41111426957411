import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class InvitacionLogic {
    static instance = null;
    static createInstance() {
        var object = new InvitacionLogic();
        return object;
    }

    static getInstance() {
        if (!InvitacionLogic.instance) {
            InvitacionLogic.instance = InvitacionLogic.createInstance();
        }
        return InvitacionLogic.instance;
    }

    constructor() {
    }

    async invitar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/invitacion/enviar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[InvitacionLogic] invitar()::ERROR >>> "+error);
        }
    }

    async verificar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/invitacion/verificar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[InvitacionLogic] verificar()::ERROR >>> "+error);
        }
    }

    async registrarusuario(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var encryptedEmail = Buffer.from(objRequest.email).toString('base64');
            var encryptedClave = Buffer.from(objRequest.clave).toString('base64');

            var formData = new FormData();
            formData.append('hash', objRequest.hash);
            formData.append('idPerfil', objRequest.idPerfil);
            formData.append('nombre', objRequest.nombre);
            formData.append('primerApellido', objRequest.primerApellido);
            formData.append('segundoApellido', objRequest.segundoApellido);
            formData.append('email', encryptedEmail);
            formData.append('clave', encryptedClave);
            formData.append('fileImagen', objRequest.fileImagen);

            var urlRequest = ENV_APIPATH+"/invitacion/registrarusuario";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[UsuarioLogic] crear()::ERROR >>> "+error);
        }
    }
}

const instance = InvitacionLogic.getInstance();
export default instance;